.content {
    .banner {
        width: 100%;
    }
    .wap-container {
        // padding: 0 2rem !important;
    }
    .contentStyle {
        height: '160px';
        color: '#fff';
        line-height: '160px';
        text-align: 'center';
        background: '#364d79';
    }
    .container {
        // padding: 0 200px;
        margin-top: -5px;
        .container-title {
            text-align: center;
            font-size: 30px;
            font-weight: 900;
            margin-bottom: 40px;
        }
        .container-img {
            position: relative;
            // display: flex;
            // justify-content: space-between;
            width: 100%;
            padding: 20px 20%;
            background: #EFEFEF;
            // margin: auto;
            .leftLine {
                font-size: 80px;
                color: #ccc;
                position: absolute;
                top: 50%;
                left: 10%;
            }
            .wap-leftLine {
                font-size: 1.5rem !important;
            }
            .rightLine {
                font-size: 80px;
                color: #ccc;
                position: absolute;
                top: 50%;
                right: 10%;
            }
            .wap-rightLine {
                font-size: 1.5rem !important;
                // right: 12% !important;   
            }
            .carousel {
                .container-img-vedio {
                    position: relative;
                    // padding: 0 5px;
                    .contentStyle {
                        width: 50%;
                        height: 690px;
                        image-rendering: -moz-crisp-edges;
                        image-rendering: -o-crisp-edges;
                        image-rendering: -webkit-optimize-contrast;
                        image-rendering: crisp-edges;
                        -ms-interpolation-mode: nearest-neighbor;
                    }
                    .wap-contentStyle {
                        height: 156px !important;
                    }
                    // video {
                    //     cursor: pointer;
                    //     width: 100%;
                    // }
                    // .vedioStyle {
                    //     height: 690px;
                    // }
                    video {
                        cursor: pointer;
                        // width: 100%;
                        height: 100%;
                    }
                    .videoStyle {
                        position: absolute;
                        left: 50%;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                    // .videoStyle {
                    //     position: absolute;
                    //     left: 50%;
                    //     top: 0;
                    // }
                    .wap-vedioStyle {
                        height: 156px !important;
                        background: #fff !important;
                    }
                    .player {
                        position: absolute;
                        left: calc(50% - 80px);
                        top: 265px;
                        // width: 100%;
                        // height: 690px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
        ul {
            li:nth-child(0),
            li:nth-child(1),
            li:nth-child(4) {
                background: #efefef;
            }
        }
        img {
            width: 100%;
            // object-fit: none;
        }
        .subject {
            display: flex;
            justify-content: center;
            padding: 40px;
            img {
                width: 280px;
                height: 100%;
            }
        }
        .content-intro {
            text-align: center;
            white-space: pre-line;
            margin-bottom: 3rem;
            line-height: 24px;
        }
    }
}