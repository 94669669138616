.shyz{
  padding: 30px 200px;
  .intro{
    width: 100%;
  }
  .list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 60px;
    .listItem{
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      img{
        height: 700px;
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
