.top-tab{
  display: flex;
  justify-content: stretch;
  height: 100px;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  &-item{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    img{
      height: 40px;
      margin-bottom: 10px;
    }
  }
}
