.join{
  .banner{
    width: 100%
  }
  .subject{
    display: flex;
    justify-content: center;
    padding: 40px;
    img{
      width: 280px;
      height: 100%;
    }
  }
  .container{
    padding: 30px 200px 100px 200px;
    display: flex;
    background-color: #f5f5f5;
    .list{
      color: #8b8a8c;
      font-size: 14px;
      height: 52vw;
      overflow-y: scroll;
      .listItem{
        padding: 30px 36px;
        border-radius: 50px;
        background-color: white;
        margin-bottom: 20px;
        cursor: pointer;
        .position{
          color: #000;
          font-size: 20px;
          font-weight: bold;
        }
        .experience{

        }
        .info{
          display: flex;
          align-items: flex-end;
          &-list{
            display: flex;
            align-items: center;
            &-item{
              display: flex;
              align-items: center;
              margin-right: 14px;
              letter-spacing: 5px;
              span{
                white-space: nowrap;
              }
              img{
                height: 16px;
                margin-right: 10px;
              }
            }
          }
          .hi{
            width: 50px;
            margin-left: 20px;
          }
        }
      }
    }
    .detail{
      display: flex;
      flex: 1;
      margin-left: 30px;
      flex-direction: column;
      background-color: white;
      padding: 30px 36px;
      border-radius: 50px;
      &-top{
        display: flex;
        justify-content: space-between;
        &-left{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .info{
            &-list{
              display: flex;
              margin-top: 10px;
              &-item{
                display: flex;
                align-items: center;
                margin-right: 14px;
                letter-spacing: 5px;
                font-size: 14px;
                color: #8b8a8c;
                span{
                  white-space: nowrap;
                }
                img{
                  height: 16px;
                  margin-right: 10px;
                }
              }
            }
          }
          .position{
            color: #f47e21;
            font-size: 24px;
            font-weight: bold;
          }
          .experience{
            color: #8b8a8c;
            margin-top: 6px;
          }
        }
        &-right{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          img{
            width: 100px;
          }
          p{
            font-size: 12px;
            color: #8b8a8c;
          }
        }
      }
      &-bottom{
        color: #646464;
        margin-top: 60px;
        .title{
          color: #000;
          font-size: 26px;
          font-weight: bold;
          margin-top: 30px;
          margin-bottom: 15px;
        }
        .content-list{
          line-height: 30px;
          font-size: 14px;
        }
      }
    }
  }
  .wap-join-container{
    padding: 0;
    flex-direction: column;
    .list{
      height: unset;
      overflow-y: unset;
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .listItem{
        box-sizing: border-box;
        margin-left: 2%;
        width: 46%;
        padding: 1.5rem;
        .info{
          .hi{
            width: 3rem;
          }
        }
      }
    }
    .detail{
      margin-left: 0;
      padding: 1.4rem;
      border-radius: unset;
    }
    h1{
      height: 8rem;
    }
  }
}

@media (max-width: 768px) {
  .join{
    .wap-join-container{
      .list{
        .listItem{
          width: 90%;
          margin-left: 5%;
        }
      }
    }
  }
}
