.windowHeader{
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 100px;
  .logo{
    height: 100%;
    img{
      max-height: 100%;
    }
  }
  .activeLink{
    color: #f47e21
  }
  .navList{
    display: flex;
    .navItem{
      margin-left: 50px;
      position: relative;
      .subMenu{
        width: 100%;
        text-align: center;
        background-color: white;
        padding-bottom: 15px;
        position: absolute;
        z-index: 999;
        .menuItem{
          margin-top: 15px;
          &:hover{
           a{
             color: #f47e21;
           }
          }
        }
      }
      .hide{
        display: none;
        opacity: 0;
      }
      .show{
        display: block;
        opacity: 1;
      }
    }
  }
}
