.about {
    .about-banner-container {
        position: relative;
        .about-banner-title-container {
            position: absolute;
            color: #fff;
            top: 16%;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .about-banner-title {
                p {
                    text-align: justify;
                    &:first-child {
                        font-weight: bold;
                        font-size: 3em;
                    }
                    &:last-child {
                        margin-top: -30px;
                    }
                    &:after {
                        content: '';
                        width: 100%;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .container {
        // padding: 0 180px;
    }
    .intro {
        display: flex;
        align-items: center;
        padding-top: 30px;
        .introContent {
            display: flex;
            flex: 1;
            line-height: 30px;
            padding-right: 30px;
        }
        .introImg {
            display: flex;
            flex: 1;
            padding-left: 30px;
            img {
                display: inline-block;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
    .core-img-container {
        position: relative;
        .core-title {
            position: absolute;
            top: 11%;
            color: #fff;
            font-size: 35px;
            left: 18%;
        }
        .core-subtitle {
            position: absolute;
            top: 21%;
            left: 14%;
            color: #fff;
            line-height: 45px;
            font-size: 20px;
            .core-subtitle-top {
                font-weight: bold;
                font-size: 22px;
            }
        }
    }
    .partners {
        // padding: 30px 180px;
        img {
            width: 100%;
        }
    }
    .contact-info-container {
        margin: 130px 180px 300px 180px;
        position: relative;
        .contact-info-detail {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            color: #fff;
            .contact-info-list {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 5% 4%;
                li {
                    font-size: 24px;
                }
            }
            h1 {
                margin-right: 28%;
                margin-top: 9%;
            }
        }
        img {
            width: 100%;
        }
    }
}