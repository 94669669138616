.aptamil{
  padding: 30px 200px;
  img{
    width: 100%;
    margin-top: 60px;
    &:last-child{
      margin-bottom: 200px;
    }
  }
}
