.footer{
  background-color: #faa92b;
  display: flex;
  justify-content: space-between;
  padding: 30px 180PX;
  color: #fff;
  .footerLeft{
    img{
      height: 60px;
    }
    p{
      margin-top: 20px;
      line-height: 32px;
      text-align: left;
    }
    a{
      color: #fff
    }
  }
  .footerRight{
    img{
      width: 100px;
      margin-bottom: 6px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
