html{
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,p {
  margin: 0;
  padding: 0;
}

ul li{
  list-style: none;
}
a{
  text-decoration: none;
  color: #000
}
a:focus{
  color: #000
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeContainer{
  background-image: url("./assets/images/homeBg.png");
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
