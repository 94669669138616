.wap-about {
    background-color: #fefefe;
    &-top {
        position: relative;
        img {
            width: 100%;
        }
        &-title-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 8%;
            display: flex;
            flex-direction: column;
            align-items: center;
            &-center {
                p {
                    text-align: justify;
                    line-height: 1rem;
                    &:after {
                        content: '';
                        width: 100%;
                        display: inline-block;
                    }
                }
            }
        }
        &-title {
            font-size: 30px;
            font-weight: bold;
            color: #273b98;
        }
        &-second {
            color: #273b98;
            font-size: 30px;
        }
    }
    &-intro {
        padding: 10px 20px 0 20px;
        text-align: center;
        line-height: 20px;
        img {
            margin-top: 20px;
            width: 100%;
        }
    }
    .wap-core-img-container {
        position: relative;
        .wap-core-title {
            position: absolute;
            top: 9%;
            color: #fff;
            font-size: 20px;
            left: 10%;
        }
        .wap-core-subtitle {
            position: absolute;
            top: 20%;
            left: 5%;
            color: #fff;
            line-height: 20px;
            font-size: 12px;
            .wap-core-subtitle-top {
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    &-subject {
        display: flex;
        justify-content: center;
        &-container {
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
    &-partners {
        padding: 2rem;
        img {
            width: 100%;
        }
    }
    .wap-contact-info-container {
        margin: 2rem;
        position: relative;
        box-sizing: border-box;
        .wap-contact-info-detail {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            color: #fff;
            .wap-contact-info-list {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 1rem 1.4rem;
                li {
                    font-size: 0.5rem;
                    p {
                        &:first-child {
                            font-weight: bold;
                        }
                    }
                }
            }
            h1 {
                margin-right: 18%;
                margin-top: 9%;
                font-size: 16px;
                white-space: nowrap;
            }
        }
        img {
            width: 100%;
        }
    }
}