.nav-bar {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    img {
        height: 100%;
    }
}