.home{
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  .homeContent{
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10%;
    letter-spacing: 20px;
    justify-content: center;
    color: #273b98;
    p{
      text-align: justify;
      line-height: 40px;
      &:after {
        content: '';
        width: 100%;
        display: inline-block;
      }
    }
    .title{
      font-size: 60px;
      font-weight: bold;
    }
    .second{
      font-size: 60px;
    }
    .third{
      color: #000;
      font-size: 26px;
      margin-top: 10px;
    }
  }
  .copyright{
    text-align: center;
    padding-bottom: 30px;
  }
}
