.case{
  .banner{
    width: 100%
  }
  .subject{
    display: flex;
    justify-content: center;
    padding: 40px;
    img{
      width: 280px;
      height: 100%;
    }
  }
  .caseContainer{
    padding: 30px 200px;
    .caseItem{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-size: cover;
      margin-bottom: 60px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .bottom{
        position: absolute;
        right: 0;
        left: 0;
        background-color: rgba(0,0,0,0.7);
        display: flex;
        align-items: center;
        padding: 10px 20px;
        justify-content: space-between;
        .introContainer{
          display: flex;
          color: #fff;
          align-items: center;
          width: 70%;
          overflow: hidden;
          .introContent{
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-right: 10px;
            overflow: hidden;
            .content{
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .logo{
            width: 80px;
            margin-right: 10px;
          }
          .title{
            font-size: 26px;
          }
        }
        .button{
          cursor: pointer;
          padding: 5px 14px;
          border-radius: 20px;
          background-color: white;
          word-break: keep-all;
        }
      }
      .wap-bottom{
        padding: 1rem;
        .introContainer{
          .logo{
            width: 2rem;
            margin-right: 10px;
          }
          .title{
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
