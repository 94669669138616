.tab-bar{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 50px;
  background-color: white;
  justify-content: stretch;
  padding: 5px;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  .tab-item{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #656565;
    img{
      height: 24px;
      object-fit: cover;
      margin-bottom: 6px;
    }
  }
  .tab-bar-item-focused{
    color: #000;
  }
}
