.private{
  .banner{
    width: 100%;
  }
  .wap-container{
    padding: 1rem 2rem !important;
  }
  .container{
    padding: 30px 200px;
    img{
      width: 100%;
      margin-top: 100px;
      &:last-child{
        margin-bottom: 300px;
      }
    }
  }
}
