.search{
  .banner{
    width: 100%
  }
  .search-banner-container{
    position: relative;
    .search-banner-title-container{
      position: absolute;
      color: #fff;
      top: 25%;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .search-banner-title{
        p{
          text-align: justify;
          &:first-child{
            font-weight: bold;
            font-size: 3em;
          }
          &:last-child{
            margin-top: -30px;
          }
          &:after {
            content: '';
            width: 100%;
            display: inline-block;
          }
        }
      }
    }
  }
  .subject{
    display: flex;
    justify-content: center;
    padding: 40px;
    img{
      width: 280px;
      height: 100%;
    }
  }
  .funnel{
    display: flex;
    justify-content: center;
    align-items: center;
    .funnel-container{
      position: relative;
      width: 70%;
      p{
        white-space: nowrap;
        font-size: 1.3em;
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        &:last-child{
          left: 78%;
        }
        &:first-child{
          right: 78%;
        }
      }
      .funnel-list{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        left: 0;
        right: 0;
        top: 10%;
        bottom: 28%;
        white-space: pre-line;
        li{
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          color: #fff;
          font-size: 1em;
        }
      }
    }
    .funnel-bg{
      img{
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .wap-container{
    padding: 1rem 2rem !important;
    img{
      width: 100% !important;
    }
    .funnel{
      .funnel-container{
        p{
          font-weight: bold;
        }
        .funnel-list{
          li{
            &:last-child{
              font-size: 2vw;
            }
          }
        }
      }
    }

  }
  .container{
    padding: 30px 200px;
    text-align: center;
    p{
      text-align: center;
      line-height: 30px;
    }
    img{
      width: 80%;
    }
    .last-img{
      margin-top: 10%;
      margin-bottom: 100px;
    }
  }
}
