.wap-footer{
  background-color: #faa92b;
  padding: 10px 0;
  &-info{
    text-align: center;
    font-size: 12px;
    line-height: 1.3rem;
    color: white;
    a{
      color: white;
    }
  }
}
