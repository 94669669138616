.subtitle{
  .titleContainer{
    position: relative;
    font-size: 4rem;
    color: #eaeaea;
    font-weight: bold;
    letter-spacing: 10px;
    text-align: center;
    .title{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      color: #000;
      justify-content: center;
      align-items: center;
      font-size: 30px;
    }
  }
}
